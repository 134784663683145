import { Row } from 'react-bootstrap'
import { views } from './views'
import './contact.css'

const LeftNav = ({ activeView , setActive }) => {

    return <div style={{overflow:"scroll"}}>
        <Row className = {"p-2 m-2"} >
            <img style={{cursor: "pointer", width: "100%"}} onClick={() => setActive(views.NEW)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-newmessage-button.svg"} alt={"New Message"} />
        </Row>
        <Row className ={"contact-left-navitem p-2 m-2"}>
            <img style={{cursor: "pointer" }} onClick={() => setActive(views.INBOX)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-menu-inbox-button.svg"} alt={"Inbox"} />
        </Row>
        <Row className = {"contact-left-navitem p-2 m-2"}>
            <img style={{cursor: "pointer" }} onClick={() => setActive(views.SENT)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-menu-sent-button.svg"} alt={"Sent Messages"} />
        </Row>
        <Row className = {"contact-left-navitem p-2 m-2"}>
            <img style={{cursor: "pointer" }} onClick={() => setActive(views.TRASH)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-menu-trash-button.svg"} alt={"Trash"} />
        </Row>
    </div>
}
export default LeftNav