import React, { Suspense } from 'react';
import Landing from './components/Landing';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GameStateProvider } from './context/provider'

function App() {
  return (  
    
      <GameStateProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Landing />
        </Suspense>
      </GameStateProvider>
    
  ); 
}

export default App;
