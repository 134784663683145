import { useEffect, useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import Video from './../viewer/Video'
import Audio from './../viewer/Audio';
import Image from './../viewer/Image';
import './contact.css'
import { views } from './views'
import React from 'react';

const Mail = ({ vh, mail, back, trash, height, setViewed }) => {

    const [attach, setAttach] = useState(null)
    const subjectLine = mail.subject ? mail.subject.replace("Re:","") : "";
    const hideAttach = () => {
        setAttach(null)
    }

    useEffect(() => {
        setViewed(mail.id)
    }, [mail.id, setViewed])

    return <>
        {attach?.type === "audio" && <Audio icon={{ label: attach.label, url: [attach.url], vh: vh }} close={hideAttach} />}
        {attach?.type === "image" && <Image icon={{ label: attach.label, url: [attach.url], vh: vh }} close={hideAttach} />}
        {attach?.type === "video" && <Video icon={{ label: attach.label, url: [attach.url] }} close={hideAttach} controls />}
        <Row className="mailContainer">
            <Col xs={12} className="mb-3 mt-2 pl-0">
                <Button className="ml-4 mt-4" variant="secondary" size="lg" onClick={() => back(mail.type)}>
                    Back
                </Button>
            </Col>
            <Col xs={12} className="m-0">
                <Row className="p-2 mx-2 mb-2 d-flex justify-content-between">
                    <span>Sender: {" "}{mail.sender}</span>
                    <span>Subject: {" "}{subjectLine}</span>
                    <span>Date, Time: {" "}{mail.dateTime}</span>
                </Row>
                <Row className="p-2 m-2" style={{ boxSizing: 'border-box', backgroundColor: 'white', border: "1px solid lightgray", height: height, overflowY: "scroll", width: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <span style={{whiteSpace: "pre-line"}}>{mail.body}</span>
                        <div style={{height: "20px"}}></div>
                        <ul >
                            {mail?.attachments?.map( attachment => (
                                    <li key={attachment.label}>
                                        <button className="link-primary" onClick={() => setAttach(attachment)}>{attachment.label}</button>
                                        <p style={{whiteSpace: "pre-line"}}>{attachment.description}</p>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </Row>
                {mail.type !== views.TRASH && <Row className="p-2 m-2 float-right">
                    <Button variant="secondary" size="lg" onClick={() => trash(mail)} >
                        Trash
                    </Button>
                </Row>}
            </Col>
        </Row>
    </>
}
export default Mail