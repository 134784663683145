import { useState, useContext, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { views } from './views'
import Header from './Header';
import LeftNav from './LeftNav'
import MailsList from './MailsList'
import ViewMail from './ViewMail'
import NewMail from './NewMail'
import { qna, useMediaQuery, prettyDate, sendKlaviyoEvent } from '../../_shared/utils'
import { Row, Col } from 'react-bootstrap'
import { GameStateContext } from '../../context/context'
import { mailNoMatch, mailAttachments, mailConfig, mailConfig_e1_greeting, mailConfig_e1_cta1_question, mailConfig_e1_cta1_answer, mailConfig_e2_greeting, mailConfig_e3_greeting, mailConfig_e4_greeting, mailConfig_e5_greeting,mailConfig_e5_cta1_question,  mailConfig_e5_cta1_answer, mailConfig_e6_greeting } from '../../_shared/config';
import './contact.css'

const Contact = ({ icon, close }) => {

    const vh = 74; //vertical height of image viewer 

    let small = useMediaQuery('(max-width: 767px)')
    const context = useContext(GameStateContext);
    const defaultView = views.INBOX

    let { episode, cta, chat, ep1_greeting, ep2_greeting, ep3_greeting, ep4_greeting, ep5_greeting, ep6_greeting, episodeEnd } = context?.getState()
    const key = parseInt(episode) + cta / 10;
    const recepient = mailConfig.toFrom;
    const subject = mailConfig.matchSubject[key]
    const toBody = "";

    const [activeView, setActiveView] = useState(defaultView)
    const [activeMail, setActiveMail] = useState()
    const [showMenu, setshowMenu] = useState(true)
    const no_good_match = "no good match found in kb.";
    const [showSpinner, setShowSpinner] = useState(false)

    const currentUserEmail = 'player@hakdetective.com'

    const [mails, setEmailsList] = useState((chat) || [])

    const toggleMenu = () => {
        setshowMenu(!showMenu)
    }

    const showMail = (mail) => {
        setActiveView(views.MAIL)
        setActiveMail(mail)
        setshowMenu(false)
    }

    const setMails = (mails) => {
        setEmailsList(mails)
        context.updateStateChatOnly(mails)
    }

    const setViewed = (id) => {
        let mailsCopy = [...mails]

        let markViewed = mailsCopy.find(m => m.id === id)
        markViewed.viewed = true;

        setMails([...mailsCopy.filter(m => m.id !== id), { ...markViewed }])
    }

    const sendMail = async (mail) => {
        if (!key || episodeEnd) return;

        let incoming = {
            type: views.INBOX,
            sender: recepient,
            to: currentUserEmail,
            dateTime: prettyDate(),
            viewed: false
        }

        let playerResponse = mail.body;

        let chat = no_good_match;

        setShowSpinner(true)
        if (parseInt(episode) === 1 && parseInt(cta) === 1) {
            if (!ep1_greeting) {
                incoming.body = mailConfig_e1_greeting;
                context.setEpisode1Greeting(true)
                incoming.subject = mailConfig.matchSubject[key]
                setMails([...mails, { ...incoming, id: Date.now() + 1000 }])
            }
            else {
                if (mailConfig_e1_cta1_question.includes(playerResponse)) {
                    incoming.body = mailConfig_e1_cta1_answer;
                    context.updateStateCtaOnly(cta + 1);
                    incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];
                }
                else {
                    incoming.body = mailNoMatch[key];
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];
                }
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }

            // incoming.body = mailConfig_e1_greeting;
            // context.updateStateCtaOnly(2)
        }
        else if (parseInt(episode) === 1 && parseInt(cta) === 2) {

            //1 - talk to bot and add reply to inbox
            let response = await qna(playerResponse, episode, cta)
            const data = await response.json();
            chat = data?.answers[0]?.answer;

            //1. no answer found
            if (chat.toLowerCase() === no_good_match) {
                incoming.body = mailNoMatch[key]
                incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];

            }
            else {
                const isCorrect = chat.toLowerCase().indexOf("{{correct}}") > -1;

                //2. if is correct, add attachments 
                if (isCorrect) {
                    incoming.body = chat.replace("{{Correct}}", "").replace("{{correct}}", "");
                    // incoming.attachments = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                    incoming.attachments = mailAttachments[key];
                    context.updateStateCtaOnly(cta + 1);
                    incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];
                    context.setEpisodeEnd()
                }else{
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10]
                }
            }
            setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
        }
        else if (parseInt(episode) === 2 && [1, 2, 3, 4].includes(cta)) {

            if (!ep2_greeting) {
                incoming.body = mailConfig_e2_greeting;
                context.setEpisode2Greeting(true);
                incoming.subject = mailConfig.matchSubject[key];

                setMails([...mails, { ...incoming, id: Date.now() + 1000 }])
            }
            else {
                //1 - talk to bot and add reply to inbox
                let response = await qna(playerResponse, episode, cta)
                const data = await response.json();
                chat = data?.answers[0]?.answer;

                //1. no answer found
                if (chat.toLowerCase() === no_good_match) {
                    incoming.body = mailNoMatch[key];
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];

                }
                else {
                    const isCorrect = chat.toLowerCase().indexOf("{{correct}}") > -1;

                    if (isCorrect) {
                        incoming.body = chat.replace("{{Correct}}", "").replace("{{correct}}", "");
                        incoming.attachments = mailAttachments[key]

                        if (parseInt(cta) === 4)
                            context.setEpisodeEnd()
                        else
                            context.updateStateCtaOnly(cta + 1)
                            incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];

                    }
                }
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }
        }
        else if (parseInt(episode) === 3 && [1, 2].includes(cta)) {
            if (!ep3_greeting) {
                incoming.body = mailConfig_e3_greeting;
                context.setEpisode3Greeting(true)
                incoming.subject = mailConfig.matchSubject[key];

                setMails([...mails, { ...incoming, id: Date.now() + 1000 }])
            }
            else {
                //1 - talk to bot and add reply to inbox
                let response = await qna(playerResponse, episode, cta)
                const data = await response.json();
                chat = data?.answers[0]?.answer;

                //1. no answer found
                if (chat.toLowerCase() === no_good_match) {
                    incoming.body = mailNoMatch[key];
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];
                }
                else {
                    const isCorrect = chat.toLowerCase().indexOf("{{correct}}") > -1;

                    if (isCorrect) {
                        incoming.body = chat.replace("{{Correct}}", "").replace("{{correct}}", "");
                        incoming.attachments = mailAttachments[key]

                        if (parseInt(cta) === 2)
                            context.setEpisodeEnd()
                        else
                            context.updateStateCtaOnly(cta + 1)
                            incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];

                    }
                }
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }
        }
        else if (parseInt(episode) === 4 && [1, 2].includes(cta)) {
            if (!ep4_greeting) {
                incoming.body = mailConfig_e4_greeting;
                context.setEpisode4Greeting(true);
                incoming.subject = mailConfig.matchSubject[key]
                setMails([...mails, { ...incoming, id: Date.now() + 1000 }])
            }
            else {
                //1 - talk to bot and add reply to inbox
                let response = await qna(playerResponse, episode, cta)
                const data = await response.json();
                chat = data?.answers[0]?.answer;

                //1. no answer found
                if (chat.toLowerCase() === no_good_match) {
                    incoming.body = mailNoMatch[key]
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];
                }
                else {
                    const isCorrect = chat.toLowerCase().indexOf("{{correct}}") > -1;

                    if (isCorrect) {
                        incoming.body = chat.replace("{{Correct}}", "").replace("{{correct}}", "");
                        incoming.attachments = mailAttachments[key]

                        if (parseInt(cta) === 2)
                            context.setEpisodeEnd()
                        else
                            context.updateStateCtaOnly(cta + 1)
                            incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];

                    }
                }
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }
        }
        else if (parseInt(episode) === 5 && [1, 2].includes(cta)) {
            if (!ep5_greeting) {
                incoming.body = mailConfig_e5_greeting;
                context.setEpisode5Greeting(true)
                incoming.subject = mailConfig.matchSubject[key]
                setMails([...mails, { ...incoming, id: Date.now() + 1000 }])
            } 
            else if(parseInt(cta) === 1) { 
                if ( mailConfig_e5_cta1_question.includes(playerResponse) ) {
                    incoming.body = mailConfig_e5_cta1_answer;
                    context.updateStateCtaOnly(cta + 1);
                    incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];
                } else {
                    incoming.body = mailNoMatch[key];
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];
                } 
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }else {
                //1 - talk to bot and add reply to inbox
                let response = await qna(playerResponse, episode, cta)
                const data = await response.json();
                chat = data?.answers[0]?.answer;
 
                //1. no answer found
                if (chat.toLowerCase() === no_good_match) {
                    incoming.body = mailNoMatch[key];
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];
                }
                else {
                    const isCorrect = chat.toLowerCase().indexOf("{{correct}}") > -1;

                    if (isCorrect) {
                        incoming.body = chat.replace("{{Correct}}", "").replace("{{correct}}", "");
                        incoming.attachments = mailAttachments[key]

                        if (parseInt(cta) === 3)
                            context.setEpisodeEnd()
                        else
                            context.updateStateCtaOnly(cta + 1)
                            incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];
                    }
                }
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }
        }
        else if (parseInt(episode) === 6 && [1, 2, 3].includes(cta)) {
            if (!ep6_greeting) {
                incoming.body = mailConfig_e6_greeting;
                context.setEpisode6Greeting(true);
                incoming.subject = mailConfig.matchSubject[key]
                setMails([...mails, { ...incoming, id: Date.now() + 1000 }])
            }
            else {
                //1 - talk to bot and add reply to inbox
                let response = await qna(playerResponse, episode, cta)
                const data = await response.json();
                chat = data?.answers[0]?.answer;

                //1. no answer found
                if (chat.toLowerCase() === no_good_match) {
                    incoming.body = mailNoMatch[key]
                    incoming.subject = mailConfig.noMatchSubject[parseInt(episode) + (cta + 1) / 10];

                }
                else {
                    const isCorrect = chat.toLowerCase().indexOf("{{correct}}") > -1;

                    if (isCorrect) {
                        incoming.body = chat.replace("{{Correct}}", "").replace("{{correct}}", "");
                        incoming.attachments = mailAttachments[key]

                        if (parseInt(cta) === 4)
                            context.setEpisodeEnd()
                        else
                            context.updateStateCtaOnly(cta + 1);
                            incoming.subject = mailConfig.matchSubject[parseInt(episode) + (cta + 1) / 10];

                    }
                }
                setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, { ...incoming, id: Date.now() + 1000 }])
            }
        }

        const maxCta = { 1:2, 2:4, 3:2, 4:2, 5:2, 6:3 };

        if(parseInt(cta) === maxCta[episode]){
            console.log('sending klaviyo')
            await sendKlaviyoEvent('HAK120'+episode, currentUserEmail);
        }

        setTimeout(() => {
            setShowSpinner(false)
            setActiveView(views.INBOX)
        }, 750)
        return;

    }

    const back = (view) => {
        setActiveView(view)
    }

    const leftNavSelect = (val) => {
        setActiveView(val)
        setshowMenu(false)
    }

    useEffect(() => {
        if (small) {
            leftNavSelect(views.NEW)
        }
    }, [small])

    useEffect(() => {
        if ((parseInt(episode) === 1 && !ep1_greeting)
            || (parseInt(episode) === 2 && !ep2_greeting)
            || (parseInt(episode) === 3 && !ep3_greeting)
            || (parseInt(episode) === 4 && !ep4_greeting)
            || (parseInt(episode) === 5 && !ep5_greeting)
            || (parseInt(episode) === 6 && !ep6_greeting)) {
            sendMail({});
        }
    }, [ep1_greeting, ep2_greeting, ep3_greeting,ep4_greeting, ep5_greeting, ep6_greeting, episode, cta])

    const trashEmail = (mail) => {
        setShowSpinner(true)

        let mailsCopy = [...mails]

        let toTrash = mailsCopy.find(m => m.id === mail.id)
        toTrash.type = views.TRASH;

        setMails([...mailsCopy.filter(m => m.id !== mail.id), { ...toTrash }])

        setActiveView(views.TRASH);

        setTimeout(() => {
            setShowSpinner(false)
        }, 500)

    }

    const viewHeight = "37vh";
    const listHeight = "80vh";

    return <div className={"contactContainer"} style={{ marginTop: "0px", marginRight: "0px", backgroundColor: "white", overflow: "auto" }}>
        <Header close={close} toggleNav={toggleMenu} />
        <Row style={{ backgroundColor: "white", margin: "0px" }}>
            <Col sm={12} md={3} lg={2} >
                {((small && showMenu) || !small) && <LeftNav activeView={activeView} setActive={leftNavSelect} />}
            </Col>
            {showSpinner && <Col className={"spinnerContainer"} sm={12} md={9} lg={10} style={{ borderLeft: "1px dotted lightgray" }} >
                <Spinner className={"spinnerInside"} variant="secondary" animation="border" />
            </Col>}
            {!showSpinner && <Col sm={12} md={9} lg={10} style={{ borderLeft: "1px dotted lightgray" }}>
                {((small && !showMenu) || !small) && <>
                    {activeView === views.INBOX && <MailsList type={views.INBOX} mails={mails.filter(m => m.type === views.INBOX)} setActiveMail={showMail} height={listHeight} />}
                    {activeView === views.SENT && <MailsList type={views.SENT} mails={mails.filter(m => m.type === views.SENT)} setActiveMail={showMail} height={listHeight} />}
                    {activeView === views.TRASH && <MailsList type={views.TRASH} mails={mails.filter(m => m.type === views.TRASH)} setActiveMail={showMail} height={listHeight} />}

                    {activeView === views.NEW && <NewMail subject={subject} sendMail={sendMail} back={back} recepient={recepient} sender={currentUserEmail} toBody={toBody} height={viewHeight} />}
                    {activeView === views.MAIL && <ViewMail vh={vh} mail={activeMail} back={back} trash={trashEmail} height={viewHeight} setViewed={setViewed} />}
                </>}
            </Col>}
        </Row>
    </div>

}
export default Contact