import { useState, useContext } from 'react'
import { Button, Row, Col, CloseButton } from 'react-bootstrap'
import { views } from './views'
import { prettyDate } from '../../_shared/utils'
import { mailConfig } from '../../_shared/config';
import { GameStateContext } from '../../context/context'

import './contact.css'

const NewMail = ({  sendMail, back, recepient, sender, toBody, height }) => {

    const [body, setBody] = useState(toBody)
    const context = useContext(GameStateContext);
    const [newRecpient, setNewRecepient] = useState(recepient)
    const [bodyError, setBodyError] = useState(false);
    let { episode, cta} = context?.getState();
    const key = parseInt(episode) + cta / 10;
    const subject = mailConfig.matchSubject[key];
    const onRecepient = (e) => {
        setNewRecepient(e.target.value)
    }

    const onBody = (e) => {
        setBodyError(false)
        setBody(e.target.value)
    }

    const onSubmit = () => {
        if (!body.trim()) { setBodyError(true); return; }
        sendMail({ type: views.SENT, sender: sender, subject : subject, recepient: newRecpient, body, dateTime: prettyDate() })
    }

    return <div>
        <Col xs={12} className="mailContainer" >
            <Row className="p-2 m-2 d-flex justify-content-between">
                <span>New Message</span>
                <CloseButton onClick={() => back(views.SENT)} />
            </Row>
            <Row className="pl-1" style={{ borderTop: "1px solid white" }}>
                <Col className="pt-2" sm={12} style={{ width: "100%", marginRight: "0px" }}>
                    <span style={{ marginRight: 47 }}>To:</span>
                    <input style={{ width: "80%", border: "1px solid lightgray" }} type="text" onChange={onRecepient} value={newRecpient}></input>
                </Col>
            </Row>
            <Row className="pl-1" style={{ borderTop: "1px solid white" }}>
                <Col className="pt-2" sm={12} style={{ width: "100%", marginRight: "0px" }}>
                    <span style={{ marginRight: 47 }}>Subject:</span>
                    <input style={{ width: "80%", border: "1px solid lightgray" }} type="text" disabled onChange={onRecepient} value={subject}></input>
                </Col>
            </Row>
            <Row className="mt-3 ml-1" style={{ backgroundColor: 'white', height: height, overflowY: "scroll", width: "100%" }}>

                <textarea onChange={onBody} style={{ width: "100%", border: "1px solid lightgray" }} value={body}></textarea>

            </Row>
            {bodyError && <span style={{ color: "red" }}>*Message body cannot be empty</span>}
            <Row className="p-1 m-1 float-right">
                <Button variant="secondary" size="md" onClick={onSubmit}>
                    Send
                </Button>
            </Row>
        </Col>
    </div>
}
export default NewMail