import React, { useContext, useState , useEffect} from 'react';
import { GameStateContext } from '../../context/context'
import { getComponentTag } from '../../_shared/utils';
import { base_url, icons } from './../../_shared/config'

import './desktop.css';

const Desktop = ( { config, showContact , parentRenderProps } ) => {
    const [current, setCurrent] = useState(null);
    const [fullScreen, setFullScreen] = useState(false);
    const [iconsObj,setIconsObj] = useState(config);
    const [iconsConfigForStorage, setIconsConfigForStorage] = useState(icons); 
    const [contactNewMsgLength, setContactNewMsgLength] = useState(0);
    const context = useContext(GameStateContext);

    const close = () => {
        setCurrent(null);
        setFullScreen(false);
        parentRenderProps();
    }
    const iconClick = (icon) => {
        setCurrent(icon);
        if(icon.fullScreen) {
            setFullScreen(true)
        }
        //localstorage state change
        let newIcons  = localStorage.getItem("iconState") ? JSON.parse(localStorage.getItem("iconState")) : icons;  
        let updatedIcons = newIcons.map((eachicon, i) => {
            if(icon.child && eachicon.children){
                let childrens = eachicon.children.map((eachchildren,i) => {
                    eachchildren = eachchildren.label === icon.label ? { ...eachchildren, showReadState : false} : eachchildren;
                    return eachchildren;
                })
                eachicon = {...eachicon, children :childrens };
            }else{
                eachicon = eachicon.label === icon.label ? { ...eachicon, showReadState: false } : eachicon;
            }
            return eachicon;
        })
        // file state change
        let localStateIcons = iconsObj.map((localIcon, i) => {
            localIcon = localIcon.label === icon.label ? { ...localIcon, showReadState: false } : localIcon;
            return localIcon;
        })
        setIconsObj(localStateIcons); // current page render
        console.log("localStateIcons", iconsConfigForStorage);
        setIconsConfigForStorage(updatedIcons); // for localstorage storage
        localStorage.setItem("iconState", JSON.stringify(updatedIcons));
    }

    
    const { episode, cta, episodeEnd, ep6GoodGuyUnlock, chat } = context?.getState()

    const getIcon = (icon) => {
        if ((icon.label === "Pawn Shop Photo" && ep6GoodGuyUnlock) || (icon.unlockAt <= (parseInt(episode)) + parseFloat(cta/10) + (episodeEnd ? 0.1 : 0) )) {
                return <li key={icon.label} className="col" >
                <div className="pt-3 pb-2 hak-icon">
                    <img onClick={() => iconClick(icon)} src={icon.img} alt={icon.label} />
                    {icon.showReadState && <span className="read-icon"></span>}
                </div>
            </li>
        }
    }

    //this is only called from the good fences open contact links 
    const openContact = () => {
        setFullScreen(true)
        setCurrent({...contactIcon, goodFences:true})
    }

    const contactIcon = {
        type: "contact",
        img: `${base_url}/assets/hak12-desktop-messenger-icon.svg`,
        fullScreen: true
    }


    useEffect(() => {
        setIconsObj(config);
        //let getContactMail = JSON.parse(context?.getState()).chat;
        setContactNewMsgLength(chat.filter( i => i.type === "inbox" && !i.viewed  ).length)
    },[config, chat])

    return  <>
            {!fullScreen ? 
                <>
                <div className={current?.type === "folder" ? "row-cols-sm-6" : "row-cols-sm-2"}>
                    <ul className="row row-cols-4 row-cols-sm-4 row-cols-lg-4 row-cols-xl-6 list-unstyled list hak-icon-ul"  >
                        {iconsObj.map((icon) => (
                            getIcon (icon) 
                        ))}
                        <li>{current && getComponentTag(current, close, openContact)}</li>
                    </ul>
                    
                </div>
                {showContact && <div className="hak-contact-icon p-2">
                    {contactNewMsgLength > 0 && <span className="read-icon contact"></span>}
                    <img alt="contacticon" onClick={() => iconClick(contactIcon)} src={contactIcon.img} />
                </div>}
                </>
            : 
            current && getComponentTag(current, close, openContact)
        } 
    </>
};


export default Desktop;