export const securityStills = [
    {"Timestamp":"10:08:00","Image":"security_still_frame_100830.jpg"},
    {"Timestamp":"10:08:10","Image":"security_still_frame_100830.jpg"},
    {"Timestamp":"10:08:20","Image":"security_still_frame_100830.jpg"},
    {"Timestamp":"10:08:30","Image":"security_still_frame_100830.jpg"},
    {"Timestamp":"10:08:40","Image":"security_still_frame_100840.jpg"},
    {"Timestamp":"10:08:50","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:09:00","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:09:10","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:09:20","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:09:30","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:09:40","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:09:50","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:10:00","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:10:10","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:10:20","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:10:30","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:10:40","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:10:50","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:11:00","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:11:10","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:11:20","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:11:30","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:11:40","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:11:50","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:12:00","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:12:10","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:12:20","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:12:30","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:12:40","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:12:50","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:13:00","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:13:10","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:13:20","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:13:30","Image":"security_still_frame_100850.jpg"},
    {"Timestamp":"10:13:40","Image":"security_still_frame_101340.jpg"},
    {"Timestamp":"10:13:50","Image":"security_still_frame_101350.jpg"},
    {"Timestamp":"10:14:00","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:14:10","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:14:20","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:14:30","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:14:40","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:14:50","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:15:00","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:15:10","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:15:20","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:15:30","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:15:40","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:15:50","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:16:00","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:16:10","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:16:20","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:16:30","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:16:40","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:16:50","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:17:00","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:17:10","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:17:20","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:17:30","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:17:40","Image":"security_still_frame_101400.jpg"},
    {"Timestamp":"10:17:50","Image":"security_still_frame_101400.jpg"}
]

export const mailAttachments = {
    1.2: [{ type: "audio", label: "Finale Audio 1", url: "hak12_finale_audio_ep1.mp3" }],
    2.3: [
    { type: "image", label: "Text Screenshots 1", url: "harrison_texts_1.jpg"} , 
    { type: "image", label: "Text Screenshots 2", url: "harrison_texts_2.jpg"},
    { type: "image", label: "Text Screenshots 3", url: "harrison_texts_3.jpg"},
    { type: "image", label: "Text Screenshots 4", url: "harrison_texts_4.jpg"},
    { type: "image", label: "Text Screenshots 5", url: "harrison_texts_5.jpg"},
    { type: "image", label: "Text Screenshots 6", url: "harrison_texts_6.jpg"}],
    2.4: [{ type: "audio", label: "Finale Audio 2", url: "hak12_finale_audio_ep2.mp3" }],
    3.1: [{ type: "audio", label: "Answering Machine Message", url: "answering_machine.mp3" }, 
    { type: "image", label: "Nathan’s Login Page", url: "nathans_login_page_ep3.jpg" }],
    3.2: [{ type: "image", label: "Nathan’s Unsent Email Draft", url: "nathans_unsent_email_draft.jpg" },
    { type: "audio", label: "Finale Audio 3", url: "hak12_finale_audio_ep3.mp3" }],
    4.2: [{ type: "video", label: "Hidden Camera Video", url: "hidden_camera_video.mp4" },
    { type: "audio", label: "Finale Audio 4", url: "hak12_finale_audio_ep4.mp3" }],
    5.2: [{ type: "audio", label: "Finale Audio 5", url: "hak12_finale_audio_ep5.mp3" }],
    6.1 : [{ type: "video", label: "Marcus Video", url: "marcus_video.mp4" }, 
    { type: "image", label: "Safe Photo", url: "safe_photo_2.jpg" },
    { type: "image", label: "Safe Instructions", url: "philax_safe_instructions.jpg"},
    { type: "audio", label: "Safe Instructions 1", url: "safe_example_audio.mp3" }],
    6.2 : [{ type: "image", label: "Safe Photo #2", url: "safe_photo_1.jpg" }],
    6.3 : [{ type: "video", label: "Finale Video", url: "hak12_finale_video_ep6.mp4" }],
}

export const base_url = window.location.origin;

const caseLogText = `
<p><b>Case Log #1</b></p><p>
Evidence Collected:
</p><ul><li>Sending the detective an ASA regulation notebook so they can see what the organization is like.  </li><li>I also picked up a brochure for the Three Pines Ice Rink. The map inside could be useful.&nbsp;</li><li> Kingston really came through with the unethically sourced evidence. He posted all kinds of top-secret info on his blog: a crime scene 
report, Nathan’s autopsy, interviews the police conducted, and even a readout of the rink’s security system. No clue how he did that, 
but I printed them all out just in case he got forced to take them down.&nbsp;</li>
<li> Speaking of The Lee Report, I’ve been checking the site obsessively nowadays in case Kingston drops any more leaked police documents. Ended up reading through a bunch of the older posts. It’s strange but useful to see the people I see every day through the eyes 
of people who don't know them as well. Uploaded relevant screenshots. </br>
<li> A local magazine published a profile on Nathan a while back. It covers the basics of who all the suspects are. Including me. Being a 
person of interest in a murder also makes me want to scream into a pillow. </li>
<li> Did a lot of looking around outside the building for any sign of the killer the police might have missed. Found a weird note. It was in 
the mud and too wet to pick up without tearing, so I uploaded a photo. </li>
<li> Found a bag on the ground outside the rink. No clue how anyone missed it; maybe it didn't look out of place? The cops in this town 
have never had to investigate a murder. There’s another weird note inside.</li>
<li> Years ago, Nathan gave me one of his old medals as a gift, and I kept it as a good luck charm ever since. It was in my pocket on the 
day he died. It’s selfish, but I’m worried the police will take it as evidence and I’ll never see it again. Sending it out for safekeeping.</li>  
<li> Managed to install a Philax security login to the case’s site. Can’t figure out a way in. Hoping the detective can take it from there.</li></br> 
Notes: </br>
I’ve been having trouble sleeping again. I keep imagining what his body must have looked like with his throat torn up. Then, when I fall asleep, I 
have this dream where I look down and I’m wearing a pair of skates that are completely soaked in blood. </br> 
What would have happened if I didn’t say those awful things to him? If I didn’t get so worked up that I stormed out and left my skate blade 
behind? </br> 
Once, when we were on our way to one of my first tournaments, I was tossing and turning because I was trying to get some sleep but the bus 
was too cold. Nathan took off his jacket to cover me. He always looked out for me. All this investigating is the only thing I can do to return the 
favor.&nbsp;</li></ul>
`
const caseLogText2 = `
<p><b>Case Log #2</b></p><p>
Evidence Collected:
</p><ul><li>Snuck into the staff lounge at the rink and found some notes Nathan made. Mostly about warm-up routines, but there are some personal notes on there. It’s weird seeing his handwriting and realizing that there’s a finite amount of it in the world.  </li><li>I found one of Anne’s business cards and what appears to be a piece of Harrison’s merchandise on the floor under the table in a conference room. Weird they’d just drop that stuff. Maybe relevant?&nbsp;
<li> Carol left her office door open, and I liberated a few relevant administrative requests from inside. Nathan made one on the day he died. </li>
<li> Waited until Anne was on a conference call, then went into her purse. Dug through designer perfume, makeup, and sunglasses until I hit the jackpot: her planner. Tore out the page from the week of the murder. </li>
<li> Kingston started to make good on his promise to help out. Sent over a draft of an article about Harrison that a colleague of his was working on. It shows a side of Harrison that Anne wouldn’t let go to print in a million years.</li>
<li> Kingston also sent some emails between Lillian and Marcus. He convinced Lillian to send him the emails as part of a story he was working on about their split.</li>  
<li> I posted a screenshot of a pretty weird and kind of worrying comment someone made on Kingston’s blog.</li></br> 
Notes: </br>
Kingston seems to have brought renewed attention to the case on his blog just as public interest was starting to fade. Which I think is a good thing. </br> 
But still, it’s strange to think that there are so many people in the world who cared about Nathan in an abstract sense, but who can forget him so easily. Who need to be reminded of what happened to him by a sufficiently splashy headline. I forget to eat some days because I’m so preoccupied thinking about how he’s dead. </br> 
&nbsp;</li></ul>
`
const caseLogText3 = `
<p><b>Case Log #3</b></p><p>
Evidence Collected:
</p><ul><li>I searched through the ASA’s online bulletin for anything about the door’s codes and, after an energy drink-fueled all nighter, finally got lucky. There was some discussion between Nathan and Carol when those first got set up last year. Took a screenshot.&nbsp;
<li> I ran Nathan’s name through the ASA’s database of internally filed complaints and found a few things. Some submitted by him, some about him. May be helpful. </li>
<li> Not entirely proud of this one, but a card that Nathan appears to have written and a pretty personal letter both happened to “fall” out of Lillian’s bag. </li>
<li> Hung around the rink until Marcus and Carol left for the day and snuck into the employee lounge to look around for anything I might’ve missed earlier. Marcus posted a very strongly worded public memo shortly before the murder, so I took it from the rink’s bulletin board.</li>
<li> Another interesting find from the lounge: Found a folded copy of the rink’s rules. Looks like Nathan wrote on it. May be nothing, but may be something? Seems suspicious.</li>  
<li> I also found a few of Nathan’s personal effects in the lost and found. It kind of hurts to still have them around. Maybe they’ll be helpful.</li>
<li> Did more digging into Harrison’s Birdlr and found some pretty interesting, and pretty worrying, stuff. Trying not to take what he said about me too personally and focus on what might be relevant to the case. I’m not even his direct competition, so I don’t get his anger at me.</li></br> 
Notes: </br>
Maybe I’m just getting cocky, but I think I might be getting good at this investigating thing. </br> 
I’ve definitely been less ambitious about my skating career lately. No time with all this investigating. Part of me misses feeling the rush of cold air on my face when I jump, but there’s no way I’d be able to enjoy it if I actually took the time to practice. Not when I’ve got more important things to do. </br> 
&nbsp;</li></ul>
`

const caseLogText4 = `
<p><b>Case Log #4</b></p><p>
Evidence Collected:
</p><ul><li>Not much on Nathan’s computer beyond that email draft, but I pulled some screenshots of direct message conversations Nathan had with Anne from his computer.  </li><li>I found a handwritten note addressed to Anne in her purse.&nbsp;
<li> Nathan had some instructions for a security system in his office. Might be relevant for once we make it to the house. </li>
<li> There was also a coaster and a keychain on Nathan’s desk. They were some of the last things he touched before he died. I don’t want them to be thrown away when his office is cleared out, but I also don’t want to hold onto physical evidence that I broke into his office. So I’m sending them over to the detective. </li>
<li> I found a note that seems to reference a skating program. Nathan and Anne spoke about it in a confusing way, though, so it seems smart to hold onto it. </li>
<li> Since I’ve spent so much time indoors avoiding the press and other onlookers, it gave me the chance to look through everyone’s public social media for clues. Not much there, but I did find some fan-archived clips of livestreams Harrison and Lillian did where they both seem pretty upset with Nathan.</li>  
<li> Carol put her handbag down on a bench the other day while she was taking down some old flyers from the wall in the hallway. In the three seconds I had to go through it, I managed to find a note from Marcus of all people. Interesting.</li>
<li> Since we’re looking for an address, I got a map of Three Pines to help the detective get familiar with the layout of the town. </li>
<li> Sending the detective an article by our ex-ally Kingston Lee.</li>
Notes: </br>
It was tough to go through Nathan’s office. Mostly because I had to keep stopping to collect myself. Some things just reminded me too much of him; a framed photo of me winning my very first junior division medal, a lighter he’d always fiddle around with… All the normal, boring possessions that are the only things left of him now that he’s gone. </br> 
It was also difficult for the more practical reason that Nathan was honestly kind of a human tornado. He had an “organized chaos” approach to file management, to say the least. It was hard to separate out anything that might be of some use from the confusing scribbles and half-formed thoughts he wrote down. There was one pretty juvenile drawing of Marcus that I’ll admit made me laugh. Still, I found a few things that might help us figure out where this secret place could be.&nbsp;</li></ul>
`
const caseLogText5 = `
<p><b>Case Log #5</b></p><p>
Evidence Collected:
</p><ul><li>I went to the rink to gather what I could about the light booth, and on the way, I found a cryptic note. It was taped to my locker and very clearly addressed to me. I uploaded a picture for the detective to take a look at. </li>
<li> While I was in the locker room, I decided to take a quick look around. I found an old room reservation sheet in Harrison’s bag, and I may have slipped into Lillian’s journal and taken a page. It seemed to be from the week leading up to the murder, so it might prove useful to track her movements.</li>
<li> When I finally did get to the light booth, I saw a couple of empty mint tins and a set of stickers. They could be old, but they reminded me of a blank contract I saw in the conference room and the system Nathan would use as a communication exercise sometimes. I took one of the tins and the stickers. </li>
<li> Fortunately, the contract was still left in the conference room printer, so I grabbed it. As I was leaving, I also saw a fancy folder in the wastepaper basket. Looks like it covers the new rink acquisition, and it’s got some weird writing on it.</li>
<li> There’s a few things I grabbed from Nathan’s while I was there. Mostly papers. </li>
<li> I uploaded the video to the desktop so we can reference it if we need to. Might be able to compare it with the security portal’s booth feed.</li>
</br>Notes: </br></br>
If the killer thinks they can scare me with a piece of paper, they haven’t met me. At least not recently. They basically told me we’re on the right track, so we can’t stop now, right? </br> 
We’ve got four suspects and a video that clearly shows corruption going on at the rink. I don’t know why Marcus and this mystery person resented Nathan so much. Were they planning to throw the competition? Rig it? Sabotage someone’s skates? Or something worse? Whatever it was, I’m glad it didn’t happen. At least not yet.
</li></ul>
`

const caseLogText6 = `
<p><b>Case Log #6</b></p><p>
Evidence Collected:
</p><ul><li>Got a copy of the police report I made after I was attacked. It doesn’t sound like they’re too confident that they’re going to be able to find whoever did this by themselves. </li>
<li> Speaking of doing the police’s jobs for them, Kingston managed to pull some strings and get a list of all the people who have registered for parking permits at the rink in the past 90 days. </li>
<li> Found a packet that describes The Lourdes Group in more detail, alongside the renovations that are currently happening to the rink as a part of the deal.</li>
<li> Another important find: a very weird note in the trash near where the ice resurfacer is stored. Lucky me; I don’t think anyone’s cleaned the place out since before the murder. </li>
<li> Marcus’s office was eerily tidy, nothing out of place. There were a few pages of what looks like a journal or the beginnings of a memoir on his desk, which could at least provide some insight into his mindset. </li>  
<li>Managed to slip into Carol’s office while she was out getting lunch; she’d left the door open behind her. Printed out a few emails I found. Wish I could have looked around there longer, but I had to be in and out fast. </li>
<li> While I was printing out Carol’s emails, I noticed that there was an award on her desk that she just so happened to mention in those emails. A little strange that it was on her desk, considering that she has a separate shelf for trophies. Could mean that it’s relevant.  </li>
<li> Kingston sent me a clip from one of Harrison’s latest livestreams. He says some interesting stuff about The Lourdes Group in it. It’s up on the website. </li>
<li> I looked through Harrison’s open locker while he was in the shower after practice and found a set of sweatbands, which he mentions in his post. Might be worth having as a point of reference. </li>
</br>Notes: </br></br>
I have this adrenaline-drenched feeling in the pit of my stomach that we’re nearing the end of this investigation. I wish I could say it’s excitement at the prospect of finding Nathan’s killer, but it’s mostly fear at the thought that a killer is going to find me first. That Nathan and I are both going to end up as unsolved mysteries.  </br> 
Haven’t been able to sleep ever since that car tried to run me down. I’ve been looking over my shoulder everywhere I go, keeping a knife under my pillow. Jumping at the smallest things. Even outside the rink, at home. Kingston’s been helping. He’s surprisingly good at being supportive when he’s not trying to coax a story out of you. But the only thing that will actually make me feel better will be finishing this once and for all.  
</li></ul>
`

export const icons = [
    {
        label: "Playlist",
        type: "external_link",
        child: true,
        img: base_url + "/assets/hak12-desktop-playlist-icon.svg",
        fullScreen: false,
        unlockAt: 1.1,
        url: "https://open.spotify.com/playlist/3g0s0epVWEsiKhRa1lzCNN?si=63ba1a60353e4dab",
        showReadState: true
        
    },
    {
        label: "Evidence",
        type: "folder",
        img: base_url + "/assets/hak12-desktop-evidence-folder-icon.svg",
        fullScreen: true,
        unlockAt: 1.1,
        showReadState: true,
        children: [
            {
                label: "Lee Report Screenshots 1",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-leereportscreenshots1_icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 1.1,
                url: ["lee_report_screenshots_1_1.jpg",
                    "lee_report_screenshots_1_2.jpg",
                    "lee_report_screenshots_1_3.jpg",
                    "lee_report_screenshots_1_4.jpg"],
                showReadState: true,
            },
            {
                label: "Note",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-note_icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 1.1,
                url: ["note.jpg"],
                showReadState: true,
            },
            {
                label: "Lee Report Screenshots 2",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-leereportscreenshots2_icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 2.1,
                url: ["lee_report_screenshots_2_1.jpg",
                    "lee_report_screenshots_2_2.jpg"],
                showReadState: true,
            },
            {
                label: "Birdlr Screenshots",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-birdlrscreenshots1_icon.svg",
                child: true,
                fullScreen: false,
                url: ["birdlr_screenshots_1_1.jpg",
                    "birdlr_screenshots_1_2.jpg",
                    "birdlr_screenshots_1_3.jpg",
                    "birdlr_screenshots_1_4.jpg",
                    "birdlr_screenshots_1_5.jpg",
                    "birdlr_screenshots_1_6.jpg"],
                unlockAt: 3.1,
                showReadState: true,
            },
            {
                label: "Harrison's Texts",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-textscreenshots_icon.svg",
                child: true,
                fullScreen: false,
                url: ["harrison_texts_1.jpg",
                    "harrison_texts_2.jpg",
                    "harrison_texts_3.jpg",
                    "harrison_texts_4.jpg",
                    "harrison_texts_5.jpg",
                    "harrison_texts_6.jpg"],
                unlockAt: 3.1,
                showReadState: true,
            },
            {
                label: "Lee Report Screenshots 3",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-leereportscreenshots3_icon.svg",
                child: true,
                fullScreen: false,
                url: ["lee_report_screenshots_3_1.jpg",
                    "lee_report_screenshots_3_2.jpg"],
                unlockAt: 3.1,
                showReadState: true,
            },
            {
                label: "Nathan’s Unsent Email Draft",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-nathansunsentemaildraft-icon.svg",
                child: true,
                fullScreen: false,
                url: ["nathans_unsent_email_draft.jpg"],
                unlockAt: 4.1,
                showReadState: true,
            },
            {
                label: "Birdlr Screenshots 2",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-birdlrscreenshots2_icon.svg",
                child: true,
                fullScreen: false,
                url: ["birdlr_screenshots_2_1.jpg",
                    "birdlr_screenshots_2_2.jpg",
                    "birdlr_screenshots_2_3.jpg",
                    "birdlr_screenshots_2_4.jpg"],
                unlockAt: 3.1,
                showReadState: true,
            },
            {
                label: "Answering Machine",
                type: "audio",
                img: base_url + "/assets/hak12-desktop-answeringmachine-icon.svg",
                child: true,
                fullScreen: false,
                url: "answering_machine.mp3",
                unlockAt: 4.1,
                showReadState: true,
            },
            {
                label: "Cease and Desist",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-ceaseanddesist-icon.svg",
                child: true,
                fullScreen: false,
                url: ["cease_and_desist.jpg"],
                unlockAt: 4.1,
                showReadState: true,
            },
            {
                label: "Lillian's Post",
                type: "video",
                img: base_url + "/assets/hak12-desktop-lillianspost-icon.svg",
                child: true,
                fullScreen: false,
                url: "lillians_post.mp4",
                unlockAt: 4.1,
                showReadState: true,
            },
            {
                label: "Harrison's Post",
                type: "video",
                img: base_url + "/assets/hak12-desktop-harrisonspost-icon.svg",
                child: true,
                fullScreen: false,
                url: "harrisons_post.mp4",
                unlockAt: 4.1,
                showReadState: true,
            },
            {
                label: "Hidden Camera Video",
                type: "video",
                img: base_url + "/assets/hak12-desktop-hiddencameravideo-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 5.1,
                url: "hidden_camera_video.mp4",
                showReadState: true,
            },
            {
                label: "Locker Note",
                type: "pdf",
                img: base_url + "/assets/hak12-desktop-lockernote-icon.svg",
                child: true,
                fullScreen: false,
                url: ["locker_note.jpg"],
                unlockAt: 5.1,
                showReadState: true,
            },
            {
                label: "Harrison's Post 2",
                type: "video",
                img: base_url + "/assets/hak12-desktop-harrisonspost2-icon.svg",
                child: true,
                fullScreen: false,
                url: "harrisons_post_2.mp4",
                unlockAt: 6.1,
                showReadState: true,
            }
        ]
    },
    {
        label: "Case Log",
        type: "folder",
        img: base_url + "/assets/hak12-desktop-caselog-folder-icon.svg",
        fullScreen: true,
        unlockAt: 1.1,
        showReadState: true,
        children: [
            {
                label: "Sofia's Journal 1",
                type: "txt",
                img: base_url + "/assets/hak12-desktop-sofiasjournal1-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 1.1,
                content: caseLogText,
                showReadState: true,
            },
            {
                label: "Sofia's Journal 2",
                type: "txt",
                img: base_url + "/assets/hak12-desktop-sofiasjournal2-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 2.1,
                content: caseLogText2,
                showReadState: true,
            },
            {
                label: "Sofia's Journal 3",
                type: "txt",
                img: base_url + "/assets/hak12-desktop-sofiasjournal3-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 3.1,
                content: caseLogText3,
                showReadState: true,
            },
            {
                label: "Sofia's Journal 4",
                type: "txt",
                img: base_url + "/assets/hak12-desktop-sofiasjournal4-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 4.1,
                content: caseLogText4,
                showReadState: true,
            },
            {
                label: "Sofia's Journal 5",
                type: "txt",
                img: base_url + "/assets/hak12-desktop-sofiasjournal5-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 5.1,
                content: caseLogText5,
                showReadState: true,
            },
            {
                label: "Sofia's Journal 6",
                type: "txt",
                img: base_url + "/assets/hak12-desktop-sofiasjournal6-icon.svg",
                child: true,
                fullScreen: false,
                unlockAt: 6.1,
                content: caseLogText6,
                showReadState: true,
            }
        ]
    },
    {
        label: "Security Portal",
        type: "security_portal",
        img: base_url + "/assets/hak12-desktop-securityportal-folder-icon.svg",
        fullScreen: true,
        unlockAt: 1.1, //2.1
        showReadState: true
    }
]

export const mailNoMatch = {
    1.1: "Sorry, I don’t think this proves me innocent. You’re going to have to keep looking. Let me know when you find something else.",
    1.2: "I don’t know, it doesn’t seem like we’ve got enough information to know for sure they’re innocent. I would hate to accidentally clear the killer this early on. Is there anyone else you think might be in the clear?",
    2.1: "No, that didn’t work. Please send another code you think will work.",
    2.2: "“There’s no account with that username.” Let’s try something else.",
    2.3: "No dice. That password isn’t working. I’ll try again, but we’ve got to get in before Harrison finds me. See if you can come up with anything else.",
    2.4: "No. Something about that doesn’t check out. Is there anyone else we could clear?",
    3.1: "That didn’t work. Maybe try again? But also maybe hurry up. I feel pretty shifty standing here. If one of the administrative staff found me loitering around here, they could kick me out of the building and change the door’s code.",
    3.2: "No, that’s not it. I wasn’t able to get in with that password. Mind trying again?",
    3.3: "ep3 cta3 no match",
    4.1: "This isn’t right. I recognize that address, and I know for sure that Nathan doesn’t own the place. Mind trying again?",
    4.2: "Dang it. That didn’t work. Please send another way to shut this thing off before we draw any attention. And please hurry! If someone sees me trying to break in and calls the cops, this entire investigation could be over.",
    5.1 : "I don’t think so. Are you sure? No one I talked to saw anything weird that day, so it has to be a time when there weren’t many people there but the rink was still open. Anything else stand out to you? ",
    5.2 : "That doesn’t make much sense to me. I wouldn’t want to make a mistake with this one. If we’re wrong, it could mean something really bad for us. For me. You saw the note left on my locker. ",
    6.1 : "Are you totally sure about that? We need to be positive before we move forward. Could you double-check your work? ",
    6.2 : "That didn't work. Can you send me another one to try? ",
    6.3 : "I don’t think so. Are you totally sure? I would double-check that. "
}

export const mailConfig_e1_greeting = `Hey, it’s Sofia. I’m not very good with words, so believe me when I say I can’t thank you enough for helping me out. I’m much more of an action kind of person. So was Nathan. Which is probably why we understood one another so well, even without needing to talk most of the time. And which is also probably why I’m doing all of this for him instead of just going to therapy like my mom wants. 

But you’ve got more important things to do than listening to me introspect. I know there must be some footage that can clear my name. Whenever you find it, send me the time stamp here. After that, let’s touch base to see if there’s anything else we’ll be able to get done with the evidence we have.
`

export const mailConfig_e2_greeting = `Okay. I’ve got the phone. Luckily, Harrison left it to charge in the locker room, per usual, while he’s out on the ice. But I don’t have too much time before he finishes practice and starts looking for it. You need to hurry. Send me a message with the code to unlock the phone as soon as you’ve found it.
`

export const mailConfig_e3_greeting = `We’ve got to learn more, no matter what we find. Once you know the keypad combination, send it here. I’ll do the rest.`;

export const mailConfig_e4_greeting = `Hey. I’m sitting in my car as we speak, ready to head off to wherever you send me. Just send me a message with the address as soon as you figure it out. `;

export const mailConfig_e5_greeting = `We’ve made it a long way, and clearly our hard work is paying off. I’m fairly sure that Nathan was murdered for finding that video, so I think we can safely assume the killer is either Marcus or whoever he was talking to. Since someone was messing with the lights during that conversation, we now have another innocent person that might have information for us. Let’s find out who that was and then narrow in on who killed Nathan.
 
I’ve turned on the light booth’s remote control system, so you should be able to look into the booth directly through the rink’s security system. Maybe that’ll help.

Send over a message with the time the video was taken and we can move forward from there.`;

export const mailConfig_e6_greeting = `It’s now or never. Let’s see this through. When you find out whoever tried to get rid of me, send me a message with their full name.`;


export const mailConfig_e1_cta1_question = [
'10:08:40',
'10:08:50',
'10:09:00',
'10:09:10',
'10:09:20',
'10:09:30',
'10:09:40',
'10:09:50',
'10:10:00',
'10:10:10',
'10:10:20',
'10:10:30',
'10:10:40',
'10:10:50',
'10:11:00',
'10:11:10',
'10:11:20',
'10:11:30',
'10:11:40',
'10:11:50',
'10:12:00',
'10:12:10',
'10:12:20',
'10:12:30',
'10:12:40',
'10:12:50',
'10:13:00',
'10:13:10',
'10:13:20',
'10:13:30',
'10:13:40',
];

export const mailConfig_e1_cta1_answer = `Yes! This is exactly what I needed to prove I didn’t do it. I told the police I left before Nathan was killed. Now I can send them solid proof from a real private investigator! 
Since you’re kind of on a roll, I wonder if you can clear anyone else while you’re at it. I’m sure I can’t be the only person who got caught on camera. If you figure out anyone else we can eliminate as a suspect, send me their name.`

export const mailConfig_e5_cta1_question = ['05:30', '5:30am', '05:30am', '5:30AM', '05:30AM', '0530', '5:30'];

export const mailConfig_e5_cta1_answer = `So if the video was taken around 5:30 am, we just need to determine who was messing with the light board at that time. They might have seen or heard who Marcus was scheming with. When you think you know who was in the light booth, send their full name my way. 
`;

export const mailConfig = {
    toFrom: "Sophia",
    matchSubject : {
        1.1: "Re: Hello",
        1.2: "Re: Proof I’m Innocent",
        1.3: "Someone We Can Trust?",
        2.1: "Re: Hello again",
        2.2: "Re: I'm in!",
        2.3: "Re: Username worked, but we still need a password",
        2.4: "Re: Secret account screenshots",
        2.5: "You're right.",
        3.1: "Re: Time to push forward",
        3.2: "Re: I’m in Nathan’s office",
        3.3: "What’s on Nathan’s computer",
        4.1: "Re: Ready if you are",
        4.2: "Re: I’m outside the house",
        4.3: "It worked!",
        5.1: "Re: Next steps",
        5.2: "Re: You got it!",
        5.3: "Following your lead",
        6.1: "Re: Let’s finish this",
        6.2: "Re: Updates on Marcus",
        6.3: "Re: It’s open",
        6.4: "That's it",
    },
    noMatchSubject : {
        1.1: "Re: Hello",
        1.2: "Re: Still Need Proof I’m Innocent",
        1.3: "Try again, sorry",
        2.1: "Re: Hello again",
        2.2: "Re: Try another one? ",
        2.3: "Re: Try something else?",
        2.4: "Re: Need a different password",
        2.5: " I’m not so sure about that",
        3.1: "Re: Time to push forward",
        3.2: "Re: Still locked out",
        3.3: "That didn’t work",
        4.1: "Re: Ready if you are",
        4.2: "Re: That can’t be it, sorry",
        4.3: "Could you give it another shot",
        5.1: "Re: Next steps",
        5.2: "Re: Sorry, no dice",
        5.3: "Are you totally sure?",
        6.1: "Re: Let’s finish this",
        6.2: "Re: Mind checking that one more time?",
        6.3: "Re: Try again?",
        6.4: "I don’t know about that",
    }
}
