import React, { useState, useEffect } from 'react'
import Desktop from '../desktop/Desktop';
import { Header } from './ModalHeader';
import Draggable from 'react-draggable'
import './viewer.css';

const Viewer = ({ icon, close }) => {

    const nodeRef = React.useRef(null);
    const [iconsState, setIconsState] = useState(icon);
    const [parentRenderState, setParentRenderState] = useState(false);
    let parentRender = () =>{
        setParentRenderState(!parentRenderState);
    }
      useEffect(() => {
        let getStoredIconState = localStorage.getItem("iconState");
        if(getStoredIconState){
            let filteredIcon = JSON.parse(getStoredIconState).find(o => o.label === icon.label)
            setIconsState(filteredIcon)
        }else{
            setIconsState(icon);
        }
        //console.log("Landing useEffect", iconsState);
       },[parentRenderState, icon])
    


    return (
        <Draggable nodeRef={nodeRef} handle=".hak-modal-header-label" bounds={"#draggable-bounds"}>
            <div ref={nodeRef} className={"hak-modal-folder"} id={"folder-bounds"}>
                <Header icon={icon} close={close} />
                <div className='pl-3 ml-3' >
                <Desktop config={iconsState.children}  parentRenderProps = {parentRender} />
                </div>
            </div>
        </Draggable>
    )
}

export default Viewer;