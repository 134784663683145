import React, { useState, useContext, useEffect} from 'react';
import Div100vh from 'react-div-100vh';
import { GameStateContext } from '../../context/context';
import { Row, Col, Container } from 'react-bootstrap';
import config from "./assets/config";
import Login from './Login'
import SavedStills from './savedstills';
import LightBooth from './lightbooth';
import './securityportal.css'
import './login.css'
const { base_url, login: {
  login_background,
  login_logo,
} } = config;

const SecurityPortal = ({close}) => {
  const [pageName, setPageName] = useState("landing");
  const context = useContext(GameStateContext);
  const { episode } = context?.getState();
  console.log("episode", episode === "5" ? "false" : "true");

  const redirectPage = (targetPage) =>{
    console.log(targetPage);
    setPageName(targetPage);
  }
  const goBackClick = () => {
    setPageName("landing")
  }

  useEffect(() => {
    console.log(episode);
  },[episode])

if(pageName === "landing") {
  return (
  <Div100vh dontresize="true" className="vh-100" style={{ backgroundColor: "white", overflow: "hidden" }}>
  {
    <Login close={close}>
    <Container fluid className={"login_container"} style={{ backgroundImage: `url(${base_url + login_background})` }} >
      <img onClick={close} src="https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-closebuttongray.svg" alt={"cls_icn"} className={"close_button"} />
      <img src={base_url + login_logo} alt={"icon"} className={"logo"} />
      <Col lg={3} className={"login_fields"}>
      <button   className="menu">
        Front Vestibule (OFFLINE)  
      </button>
      <button onClick={() => redirectPage("savedstills")} className="menu">
        Saved Stills
      </button>
      <button onClick={() => redirectPage("lightbooth")} className={`menu ${episode >= "5" ? "enable" : "disable"}`}>
        Lightbooth
      </button>
      </Col>
      <Row className="footer-logo">
        <img src="/assets/hak12-securityportal-threepineslogo.svg" alt={"hak12_icon"} className={"logo2"} />
      </Row>
  </Container>
</Login>}
</Div100vh>)
}else if(pageName === "savedstills"){ 
  return(
    <Div100vh dontresize="true" className="vh-100" style={{ backgroundColor: "white", overflow: "scroll",position:"relative", zIndex:"11" }}>
      <Container className={"section_container"} >
        <Container fluid className="section-header"> 
          <img onClick={close} src="https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-closebuttongray.svg" alt={"cls_icn"} className={"close_button"} />
          <div className="header-wrapper">
            <img src={base_url + login_logo} alt={"icon"} className={"logo"} />
            <button className="goback" onClick={goBackClick}>{"<< Go Back"}</button>
            <div className='accountdetails_container' >
            <label  className='accountdetails_container'>Account Number :</label>
            <br />
            <label className='accountdetails_container' >301634197E</label>
            </div>
            {/* <label  className="goback1">Account Number : <br></br>0123456789</label> */}
          </div>
        </Container>
        <SavedStills/>
      </Container>
    </Div100vh>
  )
}else if (pageName === "lightbooth") {
  return(
    <Div100vh dontresize="true" className="vh-100" style={{ backgroundColor: "#221F20",position:"relative" }}>
      <Container fluid className="section-headerl"> 
    <img onClick={close} src="https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-closebuttongray.svg" alt={"cls_icn"} className={"close_button"} />
    <button className='gobackl' onClick={goBackClick}>{"<< Go Back"}</button>
    </Container>
      <LightBooth/>
      
    </Div100vh>
    
  )
}


};

export default SecurityPortal;