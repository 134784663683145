import React, { useContext, useEffect, useState } from 'react';
import { GameStateContext } from '../context/context'
import Div100vh from 'react-div-100vh';
import Desktop from './desktop/Desktop';
import Dock from './desktop/Dock';
import Logout from './Logout';
import Login from './Login'
import {icons} from './../_shared/config'

const Landing = () => {

  const context = useContext(GameStateContext);
  const [iconsState, setIconsState] = useState(icons);
  const [parentRenderState, setParentRenderState] = useState(false);
  let episode = context?.getState()?.episode;
  const isLoggedIn = () => {
    return true
  }

  const isEpisodeSelected = () => {
    return context?.getState()?.episode
  }

  const logout = () => {
    context?.resetState()
  }
  let parentRender = () =>{
    setParentRenderState(!parentRenderState);
  }

  // Use effect
  useEffect(() => {
    let getStoredIconState = localStorage.getItem("iconState");
    // Filtering based on children and update the showstate 
    if(getStoredIconState){
      let getlocal = JSON.parse(getStoredIconState);
      let targetIcons = getlocal.map((element) => {
        if(element.children){
          let targetIcon = element.children.filter( i => parseInt(i.unlockAt) === parseInt(episode) && i.showReadState)
          element = targetIcon.length > 0 ? { ...element, showReadState: true } : element;
        }
        return element;
      });
      setIconsState(targetIcons)
    }else{
      setIconsState(icons);
    }
   },[parentRenderState, episode])



  if(!isLoggedIn()){
      return (<Logout />)
  }
  else if (!isEpisodeSelected()){
    return (<Login />)
  }
  else 
    return (<Div100vh dontresize="true" className="gray-logo-background"style={{overflowY:'hidden', maxHeight:'100%'}} >
        <div style={{"border": "1px solid transparent", "height": "100%"}} id="draggable-bounds">
            <Desktop config={iconsState} showContact={true} parentRenderProps = {parentRender} /> 
        </div>
      <Dock logout={logout} />
    </Div100vh>)
  
};

export default Landing;