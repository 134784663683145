import React, { useEffect, useState, useRef, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GameStateContext } from '../../context/context';
import config from "./assets/config";
import './login.css';

const { base_url, login: {
    login_background,
    login_logo
} } = config;

const VALID_PASSWORD = process.env.REACT_APP_SECURITY_PORTAL_PASS;

const Login = (props) => {
    console.log(useContext(GameStateContext));
    const { getState, saveVuMailLoginStatus } = useContext(GameStateContext);
     const [loggedIn, setLoggedIn] = useState(getState()?.securityLoggedIn);
    const [errorMessage, setErrorMessage] = useState("");
    const [erroruserMessage, setErrorUserMessage] = useState("");
    const [errorpasswordMessage, setErrorPasswordMessage] = useState(false);

    const [errorHintsMessage, setErrorHintsMessage] = useState(false);
    const [errorHintsMessageInfo, setErrorHintsMessageInfo] = useState(false);


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const passRef = useRef()

    useEffect(() => {
        errorMessage && setErrorMessage("")
    }, [password,errorMessage]);

    useEffect(() => {
        passRef?.current?.focus();
    }, [])

    const handleLogin = () => {
        setErrorUserMessage("");
        setErrorHintsMessage(false);
        setErrorHintsMessageInfo(false);

        if (username.toUpperCase() === process.env.REACT_APP_SECURITY_PORTAL_USERNAME) {
            if (password.toUpperCase() === VALID_PASSWORD) {
                setLoggedIn(true);
                saveVuMailLoginStatus(true)  
            }else {
                setErrorHintsMessageInfo(true);
                setErrorPasswordMessage(true);
            }
        }else if(username === "" ){
            setErrorUserMessage("Enter a valid user name");
        }else if(username.toUpperCase() !== process.env.REACT_APP_SECURITY_PORTAL_USERNAME){
            setErrorHintsMessageInfo(true);
            setErrorPasswordMessage(false);

        } 
        else  {
            setErrorMessage("Incorrect Password");
        }
        
    }
    let passwordHint = <div>Password hint: <br/> Where + How we both got that <br/> first garland</div>;
    let usernameError = <div> The username you are entered is<br/>incorrect or the account does not<br/> exist. Please try again.</div>;
    let passwordError = <div> The password you entered is <br /> incorrect. Please try again.</div>;
    let errorMessageInfo = errorHintsMessage ?  passwordHint :  !errorpasswordMessage ? usernameError : passwordError ;

    //errorMessageInfo = !setErrorPasswordMessage ? <div> The username you are entered is<br/>incorrect or the account does not<br/> exist. Please try again.</div> : <div> The password you entered is <br /> incorrect. Please try again.</div>;
    const handleHint = () => {
        setErrorHintsMessage(true);
        setErrorHintsMessageInfo(true);
    }

    return (<>
        {loggedIn ?
         <>{props.children}</> 
         : <Container fluid className={"login_container"} style={{ backgroundImage: `url(${base_url + login_background})` }} >
            <img onClick={props.close} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-closebuttongray.svg"} alt={"cls_icn"} className={"close_button"} />
            <img src={base_url + login_logo} alt={"icon"} className={"logo"} />
            <Col lg={3} className={"login_fields"}>
                <div className="input-wrapper">
                    <Row className="errormessage user">{erroruserMessage}</Row>
                    <Row><input type={'text'} placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} className={"login_input"} /></Row>
                    <Row><input ref={passRef} maxLength={20} onKeyUp={e => e?.key === 'Enter' && handleLogin()} placeholder={"Password"} type={"password"} value={password} onChange={e => setPassword(e.target.value)} className={"login_input"} /></Row>
                    {errorHintsMessageInfo && <Row className="errormessage hints">{errorMessageInfo}</Row>}
                    {!errorHintsMessageInfo && <Row className="errormessage">{errorMessage}</Row>}
                </div>
                <Row className="btn-wrapper login-btn-wrapper">
                    <Col className="btn-group">
                        <button onClick={handleLogin} className={"btn login_btn"}>Sign In </button>
                    </Col>
                    <Col className="btn-group">
                        <button onClick={handleHint} className={"btn cancel_btn"}>Password Hint</button>
                    </Col>
                </Row>
            </Col>
            <Row className="footer-logo">
                <img src="/assets/hak12-securityportal-threepineslogo.svg" alt={"hak12_icon"} className={"logo2"} />
            </Row>
        </Container>
        }
        
    </>)
}

export default Login;