import './contact.css'

const Header = ({ close, toggleNav }) => {

    return <div className="d-flex justify-content-between p-3" style={{backgroundColor: "lightgray"}}>
        <button onClick={toggleNav} type="button" className={"contact-menu-icon"} style={{ width: "4vh", border: "0px transparent", backgroundColor: "transparent"}} >
            <img src={"https://cdn.huntakiller.com/huntakiller/s10/menu-hamburger-white.svg"} alt={"Menu"} /> 
        </button>
        <div className="pt-2"><h5>Messenger</h5></div>
        <button onClick={close} type="button" style={{width: "2rem", border: "0px transparent", backgroundColor: "transparent"}}>
            <img src="https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-mediaplayer-close-button.svg" alt="Close"></img>        
        </button>
    </div>

}

export default Header