import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './savedstills.css';
import { securityStills } from '../../_shared/config';

const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
	return items.map((item, i) => (
		<div className="thumb" onClick={() => {setThumbIndex(i); setThumbAnimation(true); }}>
			{item}
		</div>
	));
};


const SavedStills = () => {

  const [mainIndex, setMainIndex] = useState(0);
	const [mainAnimation, setMainAnimation] = useState(false);
  const [zoomContainer, setZoomContainer] = useState(false);
  const s3_base = "https://cdn.huntakiller.com/huntakiller/s12/";

	const [thumbIndex, setThumbIndex] = useState(0);

	const [thumbAnimation, setThumbAnimation] = useState(false);

  const [imgWidth, setImgWidth] = useState(100)
  const [fetching, setFetching] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const items = securityStills.map (s => {
    return <div className="item" dataimg={s3_base+s.Image} data-value={s.Timestamp}><img alt="sliderimage" src={s3_base+s.Image} /></div> 
  })
  
  const [thumbs] = useState(thumbItems(items, [setThumbIndex, setThumbAnimation]));
  
  const zoom = (zoomIn) => {
    let selectedSlider = items[mainIndex];
    let widthPercent = imgWidth;
    widthPercent = zoomIn ? Math.max(widthPercent - 20, 100) : Math.min(widthPercent + 20, 350);
    setCurrentImage(selectedSlider.props.dataimg)
    setZoomContainer(true);
    setImgWidth(widthPercent); 
  };


  const download = (url, name, e) => {
    e.preventDefault();
    let selectedSlider = items[mainIndex];
    if (!fetching) {
      setFetching(true);
      fetch(selectedSlider.props.dataimg + "?crossorigin")
        .then(response => response.blob())
        .then(blob => {
          setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";
          a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch(console.log("Error downloading image."));
    }
  };


	const slideNext = () => {
		if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
			setThumbAnimation(true);
			setThumbIndex(thumbIndex + 1);
		}
    setImgWidth(100);
    setZoomContainer(false);
	};

	const slidePrev = () => {
		if (!thumbAnimation && thumbIndex > 0) {
			setThumbAnimation(true);
			setThumbIndex(thumbIndex - 1);
		}
    setImgWidth(100);
    setZoomContainer(false);
	};

	const syncMainBeforeChange = () => {
		setMainAnimation(true);
    setImgWidth(100);
    setZoomContainer(false);
	};

	const syncMainAfterChange = (e) => {
		setMainAnimation(false);
		if (e.type === 'action') {
			setThumbIndex(e.item);
			setThumbAnimation(false);
		} else {
			setMainIndex(thumbIndex);
		}
    setImgWidth(100);
    setZoomContainer(false);
	};

	const syncThumbs = (e) => {
		setThumbIndex(e.item);
		setThumbAnimation(false);

		if (!mainAnimation) {
			setMainIndex(e.item);
		}
    setImgWidth(100);
    setZoomContainer(false);
	};

  return [ 
    <div className="slider-container">
      <div className="slider-count"><label>Photo(s) Saved :  60</label></div>
      <div className="slider-header"><label>{securityStills[thumbIndex].Timestamp}</label></div>
      <div className='main'>
      <div className="zoom-container">
        {zoomContainer && <img className="zoom-img"  style={{ width: imgWidth + "%" }} src={currentImage} alt="test" /> }  
      </div>
        <AliceCarousel
            activeIndex={mainIndex}
            animationType="fadeout"
            animationDuration={800}
            disableDotsControls
            disableButtonsControls
            infinite
            items={items}
            mouseTracking={!thumbAnimation}
            onSlideChange={syncMainBeforeChange}
            onSlideChanged={syncMainAfterChange}
            touchTracking={!thumbAnimation}
        />
        <div className="btn-prev" onClick={slidePrev}><img alt="prevlink" src={window.location.origin + '/assets/hak12-securityportal-previousphoto.svg'} className="icon prev" /></div>
        <div className="btn-next" onClick={slideNext}><img alt="prevlink" src={window.location.origin + '/assets/hak12-securityportal-nextphoto.svg'} className="icon next" /></div>
    </div> 
    <button disabled={imgWidth >= 350} className={"btn btn-sm"} onClick={() => zoom(false)}><img style={{ width: "40px" }} src={window.location.origin + '/assets/hak12-securityportal-zoomin.svg'}  alt={"Zoom In"} /></button> 
    <button disabled={imgWidth <= 100} className={"btn btn-sm"} onClick={() => zoom(true)}><img style={{ width: "40px" }} src={window.location.origin + '/assets/hak12-securityportal-zoomout.svg'}  alt={"Zoom Out"} /></button> 
    <button className={"btn btn-sm"} onClick={(e) => download("https://cdn.huntakiller.com/summer-camp/director/saved-emails2.png", "imge", e)}><img style={{ width: "40px" }} src={window.location.origin + '/assets/hak12-securityportal-download.svg'}  alt={"Download"} /></button>
    </div>,
    <div className="thumb-container"><div className="thumbs">
        <AliceCarousel
             activeIndex={thumbIndex}
             autoWidth
             disableDotsControls
             disableButtonsControls
             items={thumbs}
             mouseTracking={false}
             onSlideChanged={syncThumbs}
             touchTracking={!mainAnimation}
        />
        <div className="btn-prev" onClick={slidePrev}><img alt="prevlink" src={window.location.origin + '/assets/hak12-securityportal-previousphoto.svg'} className="icon prev" /></div>
        <div className="btn-next" onClick={slideNext}><img alt="prevlink" src={window.location.origin + '/assets/hak12-securityportal-nextphoto.svg'} className="icon next" /></div>
    </div></div>
 ]
};

export default SavedStills;