import { useState, useEffect } from 'react';
import Audio from '../components/viewer/Audio';
import Folder from '../components/viewer/Folder';
import PDFViewer from '../components/viewer/Image'; //this can show images too
import Note from '../components/viewer/Note';
import SecurityPortal from '../components/securityportal'
import Video from '../components/viewer/Video'
import Contact from '../components/contact/Contact'
import base64 from 'base-64';

export const default_threshold_score = 80
export const passes = JSON.parse(process.env.REACT_APP_EP_PW);
const KLAVIYO_URL = "https://a.klaviyo.com/api/track?data="

export const getComponentTag = (icon, close, openContact) => {
  let components = {
    folder: Folder,
    txt: Note,
    audio: Audio,
    pdf: PDFViewer,
    security_portal: SecurityPortal,
    contact:Contact,
    video: Video
  };

  if (icon.type === "external_link") {
    window.open(icon.url, '_blank');
  }
  else {
    const TagName = components[icon.type];
    return <TagName icon={icon} close={close} openContact={openContact} />
  }
}

export const qna = (question, episode, cta) => {
  return fetch(`https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/e0420db6-99e8-4d0b-baf5-dc3d709f5cfb/generateAnswer`, {
    method: 'POST',
    body: JSON.stringify({
      "question": question,
      "top": 1,
      "scoreThreshold": default_threshold_score,
      "strictFilters": [{ "name": "episode", "value": `${episode}.${cta}` }]
    }),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'EndpointKey 69ccf40a-ac15-4259-841d-97e4ad9f382d'
    }
  });
};

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function prettyDate() {
  const today = new Date();

  let date = today.toISOString().slice(0, 10)
  let time = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  return date + " " + time
}

export function sendKlaviyoEvent(episode, userEmail) {

  let data = {
    "token": process.env.REACT_APP_KLAVIYO_TOKEN,
    "event": "episode-complete",
    "customer_properties":{
        "$email":userEmail
    },
    "properties":{
        "episode":episode,
        "source":"web"
    }
  }

  let encodedData = base64.encode(JSON.stringify(data))

  fetch(KLAVIYO_URL + encodedData)
    .then(function (response) {
      //console.log(response)
    })
    .then((data) => {
      //console.log(data)
    })
    .catch(err => console.log('Error with Klaviyo', err));

}