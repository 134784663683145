import { base_url } from '../../_shared/config';
import './viewer.css'

export const Header = ({ vumail, icon, close, secondLevel, parent }) => {
    let classLabel = `${secondLevel ? "hak-modal-header-label-secondlevel" : "hak-modal-header-label"}`
    return (
        <div className={vumail ? "hak-modal-header-vumail" : "hak-modal-header"}>
            <div style={{ width: "100%", float: "left", textAlign: "center" }} className={classLabel}>{icon.label}</div>
            {close && <div className='hak-modal-header-close'>
                <img alt="close"
                    src={`${base_url}/assets/hak12-mediaplayer-close-button.svg`}
                    onClick={() => close(parent)}
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
            </div>}
        </div >)
}