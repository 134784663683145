import { Row, Col, Badge } from 'react-bootstrap'
import './contact.css'
import { views } from './views'

const MailsList = ({ type, mails, setActiveMail, height }) => {

    let sorted = mails.sort((a, b) => a.id < b.id && (1 || -1) )
    console.log("maillists", mails);
    return <div style={{ maxHeight: height, overflowY: "auto" }}>
        <h3><i style={{ color: "lightgray" }}>{mails.length === 0 ? `NO ITEMS IN "${type.toUpperCase()}"` : type.toUpperCase()}</i></h3>
        {
            sorted.map((mail, index) => (
                <Row key={index} className={"inboxItem pt-4 pb-4 mb-3 ml-1 mr-1"} onClick={() => setActiveMail(mail)}>
                    <Col style={{ borderRight: "1px dotted gray" }} xs={4} className={"text-break"}>{!mail.viewed && type === views.INBOX && <Badge style={{ color: "green" }}>&#x25cf;</Badge>}{mail.sender || 'You'}</Col>
                    <Col style={{ borderRight: "1px dotted gray" }} xs={4} className={"text-break"}>{mail?.subject ? mail?.subject?.replace("Re:","") : ""}</Col>
                    <Col xs={4} className={"text-break d-flex justify-content-end"}>{mail.dateTime}</Col>
                </Row>))
        }
    </div>
}
export default MailsList