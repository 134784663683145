import React, { useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import lightBoothLogo from './assets/hak12-lightbooth-logo.png';
import liveLogo from './assets/hak12-lightbooth-screen-live.png';
import './lightbooth.css';

const LightBooth = () => {
    const [switch1N, setSwitch1N] = useState(false);
    const [switch2A, setSwitch2A] = useState(false);
    const [switch2U, setSwitch2U] = useState(false);
    const [switch2M, setSwitch2M] = useState(false);
    const [switch4S, setSwitch4S] = useState(false);
    const [switchMA, setSwitchMA] = useState(false);
    const s3_img = "https://cdn.huntakiller.com/huntakiller/s12/lightroom_booth_feed_"
    const [imgc, setImgc] = useState(s3_img + "off.jpg");

    const handleToggleClick = () =>{
            setSwitchMA(!switchMA);
    }
    const handleSwitchClick = (e) => {
        let targetSwitch = e.target.getAttribute("datakey");
        switch (targetSwitch) {
            case "1N":
                setSwitch1N(!switch1N);
                setSwitch2A(false);
                setSwitch2U(false);
                setSwitch2M(false);
                setSwitch4S(false);
            break;
            case "2A":
                setSwitch2A(!switch2A);
                setSwitch1N(false);
            break;
            case "2U":
                setSwitch2U(!switch2U);
                setSwitch1N(false);
            break;
            case "2M":
                setSwitch2M(!switch2M);
                setSwitch1N(false);
            break;
            case "4S":
                setSwitch4S(!switch4S);
                setSwitch1N(false);
            break;
            default :
            break;
        }

    }

    useEffect(() => {

        if(switch2A && !switch2M && !switch2U && !switch4S) setImgc(s3_img + "red.jpg");
        else if(!switch2A && !switch2M && switch2U && !switch4S) setImgc(s3_img + "blue.jpg"); 
        else if(!switch2A && switch2M && !switch2U && !switch4S) setImgc(s3_img + "green.jpg"); 
        else if(!switch2A && !switch2M && !switch2U && switch4S) setImgc(s3_img + "white.jpg"); 
        else if(switch2A && !switch2M && switch2U && !switch4S) setImgc(s3_img + "magenta.jpg");
        else if(!switch2A && switch2M && !switch2U && !switch4S) setImgc(s3_img + "yellow.jpg"); 
        else if(!switch2A && switch2M && switch2U && !switch4S) setImgc(s3_img + "cyan.jpg"); 
        else if(switch2A && switch2M && switch2U && !switch4S) setImgc(s3_img + "white.jpg");
        else setImgc(s3_img + "off.jpg");

    },[switch1N,switch2A,switch2U,switch2M,switch4S])

    return (
        <Div100vh dontresize="true" className="vh-100" style={{ backgroundColor: "#221F20", overflow: "auto",position:"relative", zIndex:"11" }}>
            <div className='lightbooth-container'>
                <img className="lightbooth-logo" src={lightBoothLogo} alt="lightboothlogo"/>
                <div className="lightbooth-screen-container dynamic-color" style={{backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: "url('"+imgc+"'"}}>
                    <div className="lightbooth-border">
                        <img className="live-icon" src={liveLogo} alt="live" />
                    </div>
                    <div className="switch-container">
                        <span onClick={(e) => handleSwitchClick(e)} datakey="1N" className={"switch N "+switch1N}></span>
                        <span onClick={(e) => handleSwitchClick(e)} datakey="2A" className={"switch A "+switch2A}></span>
                        <span onClick={(e) => handleSwitchClick(e)} datakey="2U" className={"switch U "+switch2U}></span>
                        <span onClick={(e) => handleSwitchClick(e)} datakey="2M" className={"switch M "+switch2M}></span>
                        <span onClick={(e) => handleSwitchClick(e)} datakey="4S" className={"switch S "+switch4S}></span>
                        <span onClick={(e) => handleToggleClick(e)} datakey="MA" className={"switch MA "+switchMA}></span>
                    </div>
                    <div className="lightbooth-caption">
                        <img className="serialcode" alt="lightbooth-caption" src="/assets/hak12-lightbooth-serialcode.png" />
                    </div>
                </div>
            </div>
        </Div100vh>
    )
};

export default LightBooth;